import React, { useState, useEffect, useRef } from 'react'
import styles from './FeaturedPosts.module.scss'
import Button from '../common/Button'
import { Link } from 'gatsby'

const FeaturedPosts = ({ posts }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>We’ll help you get started</h2>
          <p>
            Browse the Blog and customer stories to find what you need to
            succeed with Atlas.
          </p>
          <div className={styles.buttonContainer}>
            <Button href="/resources">See everything</Button>
          </div>
        </div>
        <div className={styles.grid}>
          {posts.map(post => (
            <PostCard
              id={post.id}
              title={post.frontmatter.title}
              slug={post.fields.slug}
              image={post.frontmatter.featuredImage}
              excerpt={post.frontmatter.meta.description}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const PostCard = ({ id, title, slug, image, excerpt }) => {
  const [hover, setHover] = useState(false)
  return (
    <Link to={slug}>
      <div
        className={styles.postCard}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div className={`${styles.cardImage} ${hover && styles.hovered}`}>
          <img src={image} alt={title} />
        </div>
        <div className={`${styles.cardContent}`}>
          <h3 style={{ color: hover ? '#0f58ff' : '#0e101b' }}>{title}</h3>
          <p>{excerpt}</p>
        </div>
      </div>
    </Link>
  )
}

export default FeaturedPosts
