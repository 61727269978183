import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Space, TextInput, Tooltip } from '@mantine/core'
import styles from './Article.module.scss'
import Button from '../common/Button'
import {
  ArrowLeftIcon,
  ChevronLeftSolidIcon,
  LinkIcon,
  LinkedInIcon,
  XIcon
} from '@atlas-co/icons'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import Content from './Content'

const slugify = string => {
  return string
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s/g, '-') // Replace each space with -
    .replace(
      /[^\w\-\u00b4\u00C0-\u00C3\u00c7\u00C9-\u00CA\u00CD\u00D3-\u00D5\u00DA\u00E0-\u00E3\u00E7\u00E9-\u00EA\u00ED\u00F3-\u00F5\u00FA]+/g,
      ''
    ) // Removes all chars that aren't words, -, ´ or some latin characters (À Á Â Ã Ç É Ê Í Ó Ô Õ Ú à á â ã ç é ê í ó ô õ ú)
}

const Article = ({ title, featuredImage, author, body, headings }) => {
  const [currentHeading, setCurrentHeading] = useState(null)
  // Create a state to hold whether the observer is enabled
  const [observerEnabled, setObserverEnabled] = useState(true)
  const [email, setEmail] = useState('')
  const [copied, setCopied] = useState(false)
  const [subscribed, setSubscribed] = useState(false)

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault()

        const targetId = this.getAttribute('href').substring(1)
        const targetElement = document.getElementById(targetId)

        if (targetElement) {
          // Disable the observer
          setObserverEnabled(false)

          targetElement.scrollIntoView({ behavior: 'smooth' })

          // Re-enable the observer after the scroll action is complete
          setTimeout(() => {
            setObserverEnabled(true)
          }, 1000) // Adjust this value as needed

          setCurrentHeading(targetId)
        }
      })
    })
  }, [])

  useEffect(() => {
    const heads = document.querySelectorAll('h2')

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          // Only update the current heading if the observer is enabled
          if (entry.isIntersecting && observerEnabled) {
            setCurrentHeading(entry.target.id)
          }
        })
      },
      {
        rootMargin: '0px 0px -100% 0px'
      }
    )

    heads.forEach(heading => observer.observe(heading))

    return () => {
      heads.forEach(heading => observer.unobserve(heading))
    }
  }, [observerEnabled]) // Add observerEnabled as a dependency

  const handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()

    formData.append('email', email)

    const form = event.target
    const data = serialize(form)

    fetch('' + '?' + stringify(data), {
      method: 'POST'
    })
      .then(() => (setEmail(''), setSubscribed(true)))
      .catch(error => console.log(error))
  }

  const shareToLinkedIn = () => {
    const url = window.location.href
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`
    window.open(linkedInUrl, '_blank')
  }

  const shareToTwitter = () => {
    const url = window.location.href
    const text = 'Check out this article!'
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(url)}`
    window.open(twitterUrl, '_blank')
  }

  const shareToOther = () => {
    const url = window.location.href
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true)
      })
      .catch(err => {
        console.error('Could not copy text: ', err)
      })
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  return (
    <div className={styles.container}>
      <div className={styles.layout}>
        <div className={styles.header}>
          <Button
            variant="minimal"
            href="/resources/"
            leftIcon={<ArrowLeftIcon color="var(--primary)" />}
          >
            Back to Blog
          </Button>
          <h1>{title}</h1>
          <div className={styles.author}>
            {author && author.image && (
              <img src={author.image} alt={author.name} />
            )}
            <span>{author.name}</span>
          </div>
          <div className={styles.shareContainer}>
            <span>Share this page</span>
            <div className={styles.socialIcons}>
              <Tooltip label="Share on LinkedIn" position="top" withArrow>
                <div className={styles.shareButton} onClick={shareToLinkedIn}>
                  <LinkedInIcon size={28} />
                </div>
              </Tooltip>
              <Tooltip label="Share on X" position="top" withArrow>
                <div className={styles.shareButton} onClick={shareToTwitter}>
                  <XIcon size={28} />
                </div>
              </Tooltip>
              <Tooltip
                label={copied ? 'Copied!' : 'Copy link'}
                position="top"
                withArrow
                opened={copied || undefined}
              >
                <div className={styles.shareButton} onClick={shareToOther}>
                  <LinkIcon size={28} />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img src={featuredImage} alt={title} />
          </div>
          <article itemScope itemType="http://schema.org/BlogPosting">
            <Content source={body} />
          </article>
        </div>
        {/* <div className={styles.rightContainer}>
        <div className={styles.tableOfContent}>
          <h3>Share with others</h3>

          <div className={styles.shareButtons}>
            <BsLinkedin size={26} onClick={shareToLinkedIn} />
            <BsTwitterX size={26} onClick={shareToTwitter} />
            <LinkIcon width={26} onClick={shareToOther} />
            {copied && <span>Copied!</span>}
          </div>
          <div className={styles.subscribeContainer}>
            <h3>Sign up for our newsletter!</h3>
            <p>
              Be the first to know about releases and industry news and
              insights.
            </p>
            <Space h="lg" />

            <form
              name="newsletter"
              method="POST"
              data-netlify="true"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="newsletter" value="newsletter" />
              <TextInput
                name="email"
                placeholder="Enter your email"
                radius="md"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
              <Space h="md" />
              <div style={{ height: '36px' }}>
                {!subscribed ? (
                  <input type="submit" className={styles.submitButton} />
                ) : (
                  <p style={{ textAlign: 'center', width: '100%' }}>
                    Thank you for subscribing!
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
        {headings?.filter(
          head => head.depth === 1 || head.depth === 2 || head.depth === 3
        ).length > 0 && (
          <div className={styles.tableOfContent}>
            <h4>On this page</h4>
            {headings.map((heading, i) => {
              if (heading.depth === 1) {
                return
              }
              if (heading.depth === 2) {
                return (
                  <a
                    // href={`#${heading.value
                    //   .replace(/\s+/g, '-')
                    //   .replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, '')}`}
                    href={`#${slugify(heading.value)}`}
                    key={i}
                    style={{
                      color:
                        currentHeading === slugify(heading.value)
                          ? 'var(--primary)'
                          : 'var(--text-secondary)'
                    }}
                  >
                    <p>
                      {heading.value.length < 51
                        ? heading.value
                        : `${heading.value.substring(0, 50)}...`}
                    </p>
                  </a>
                )
              }
              if (heading.depth === 3) {
                return (
                  <a
                    href={`#${heading.value
                      .replace(/\s+/g, '-')
                      .replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, '')
                      .toLowerCase()}`}
                    key={i}
                    style={{
                      color:
                        currentHeading === slugify(heading.value)
                          ? 'var(--primary)'
                          : 'var(--text-secondary)'
                    }}
                  >
                    <p
                      style={{
                        marginBottom: '10px',
                        marginLeft: '20px'
                      }}
                    >
                      {heading.value.length < 51
                        ? heading.value
                        : `${heading.value.substring(0, 50)}...`}
                    </p>
                  </a>
                )
              }
            })}
          </div>
        )} 
      </div> */}
      </div>
    </div>
  )
}

export default Article
