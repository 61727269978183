import React from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from '../components/common/Layout'
import PageHeader from '../components/singlePost/PageHeader'
import Article from '../components/singlePost/Article'
import KeepReading from '../components/singlePost/KeepReading'

export const SinglePostTemplate = ({
  title,
  date,
  topic,
  author,
  headings,
  featuredImage,
  body,
  posts
}) => (
  <main>
    {/* <PageHeader
      title={title}
      date={date}
      author={author}
      featuredImage={featuredImage}
      topic={topic}
    /> */}

    <Article
      title={title}
      author={author}
      featuredImage={featuredImage}
      topic={topic}
      body={body}
      headings={headings}
    />
    <KeepReading posts={posts} />
  </main>
)

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, posts } }) => {
  const relatedPosts = posts.nodes.filter(
    item => item.frontmatter.title !== post.frontmatter.title
  )
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
      socialMediaCard={post.frontmatter.featuredImage || false}
    >
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        posts={relatedPosts}
        body={post.html}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      headings {
        value
        depth
      }
      frontmatter {
        title
        template
        date(formatString: "MMMM Do, YYYY")
        topic
        author {
          name
          image
          role
        }
        featuredImage
      }
    }

    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          date(formatString: "MMMM D, YYYY")
          topic
          author {
            name
            image
            role
          }
          meta {
            title
            description
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
